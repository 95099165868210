import * as moment from 'moment';
import { last } from 'lodash';
import { DAY_NAMES } from './constants';

export function formatDateTime(dateTime) {
  return moment(dateTime).format('DD.MM.YYYY, HH:mm:ss');
}

export function formatCrontab(crontab) {
  crontab = crontab.split(' ');
  const time = `${crontab[0]}:${crontab[1]}`;
  const day = crontab[4] === '*' ? 'Everyday' : this.$t(`dayNames.${DAY_NAMES[crontab[4]]}`);
  return `${day}, ${moment(time, 'H:m').format('HH:mm:ss')}`;
}

export function formatDate(date) {
  return moment(date).format('DD.MM.YYYY');
}

export function formatToAPIDate(date) {
  return moment(date).format('YYYY-MM-DD');
}

export function getRandomColor() {
  const r = Math.floor(Math.random() * 255);
  const g = Math.floor(Math.random() * 255);
  const b = Math.floor(Math.random() * 255);
  return `rgb(${r},${g},${b})`;
}

export function parseFileNameFromUrl(url, removeTimestamp = false) {
  let fileName = decodeURI(last(url.split('/')));
  if (removeTimestamp) fileName = fileName.replace(/^[0-9.]+_/, '');
  return fileName;
}

export const ACTIVITY_EXTRA_MAPPINGS = (action, extra) => {
  return {
    posted_order: '',
    updated_order: '',
    updated_order_labor_hours: '',
    posted_comment: extra.comment,
    deleted_comment: '',
    added_used_material: extra.used_materials,
    updated_used_material: extra.used_material,
    deleted_used_material: extra.used_material,
    added_invoice: extra.invoice_number,
    updated_invoice: extra.invoice_number,
    added_delivery_note: extra.delivery_note_number,
    updated_delivery_note: extra.delivery_note_number,
    attached_drawing_files: extra.drawing_files,
    deleted_drawing_file: extra.drawing_file,
    archived_bill: parseFileNameFromUrl(extra.material_bill || '', true),
    unarchived_bill: parseFileNameFromUrl(extra.material_bill || '', true),
    attached_bill: parseFileNameFromUrl(extra.material_bill || '', true),
    downloaded_bill: parseFileNameFromUrl(extra.material_bill || '', true)
  }[action];
};

export const NOTIFICATION_EXTRA_MAPPINGS = (action, extra) => {
  return {
    uploaded_bill: extra.order_number,
    mentioned_comment: extra.order_number
  }[action];
};

export function getUsername(user, append_trigger = false) {
  return user
    ? `${append_trigger ? '@' : ''}${user.first_name.toLowerCase()}_${user.last_name.toLowerCase()}_${user.id}`
    : '';
}
